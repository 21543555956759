import React, { useRef, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import ArrowImg from '../img/arrow.svg'

function Statute() {

  const initialLoad = useRef(true);

  const scrollToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: "smooth"
      });
  };

  useEffect(() => {
      if (initialLoad.current) {
          scrollToTop();
          initialLoad.current = false;
      }
  }, []);

    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const section4Ref = useRef(null);
    const section5Ref = useRef(null);
    const section6Ref = useRef(null);
    const section7Ref = useRef(null);
    const section8Ref = useRef(null);
    const section9Ref = useRef(null);
    const section10Ref = useRef(null);
    const section11Ref = useRef(null);
    const section12Ref = useRef(null);
    const section13Ref = useRef(null);
    const section14Ref = useRef(null);
    const section15Ref = useRef(null);
    const section16Ref = useRef(null);

    const scrollToSection = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

  return (
    <div>
    <Helmet>
        <title>Устав Ассоциации IABC</title>
        <meta
        name="description"
        content="Устав Международной Ассоциацией Красоты и Ухода (International Association of Beauty and Care) IABC"
        />
        <link rel="canonical" href="https://iabc-association.com/statute"/>
      </Helmet>
    <div className="documents-wrapper mt">
    <div className="text-center">
        <div id="our-statute" className="page-title" >Устав</div>
        <div className="sub-title">МЕЖДУНАРОДНОЙ АССОЦИАЦИИ КРАСОТЫ И УХОДА "IABC"</div>
        </div>
        <p className="page-subtext">Утвержден Общим собранием членов Международной Ассоциации красоты и ухода "IABC"</p>
        <p className="page-text">Протокол № 2 от 04 июля 2019 года</p>
        <p className="page-text mb-60">г. Москва, 2019 год</p>
        <p className="sub-title"><b>Содержание:</b></p>
        <ul className="statute-details-list">
          <li onClick={() => scrollToSection(section1Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>ОБЩИЕ ПОЛОЖЕНИЯ</li>
          <li onClick={() => scrollToSection(section2Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>ПРАВОВОЕ ПОЛОЖЕНИЕ АССОЦИАЦИИ</li>
          <li onClick={() => scrollToSection(section3Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>ЦЕЛИ И ПРЕДМЕТ ДЕЯТЕЛЬНОСТИ АССОЦИАЦИИ</li>
          <li onClick={() => scrollToSection(section4Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>ПРИНЦИПЫ ДЕЯТЕЛЬНОСТИ АССОЦИАЦИИ</li>
          <li onClick={() => scrollToSection(section5Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>ФУНКЦИИ АССОЦИАЦИИ</li>
          <li onClick={() => scrollToSection(section6Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>ИСТОЧНИКИ ФОРМИРОВАНИЯ ИМУЩЕСТВА АССОЦИАЦИИ</li>
          <li onClick={() => scrollToSection(section7Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>ПОРЯДОК ВСТУПЛЕНИЯ В ЧЛЕНЫ АССОЦИАЦИИ И ВЫХОДА ИЗ ЧЛЕНОВ АССОЦИАЦИИ</li>
          <li onClick={() => scrollToSection(section8Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>ПРАВА И ОБЯЗАННОСТИ ЧЛЕНОВ АССОЦИАЦИИ</li>
          <li onClick={() => scrollToSection(section9Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>ОРГАНЫ УПРАВЛЕНИЯ АССОЦИАЦИИ</li>
          <li onClick={() => scrollToSection(section10Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>ОБЩЕЕ СОБРАНИЕ ЧЛЕНОВ АССОЦИАЦИИ</li>
          <li onClick={() => scrollToSection(section11Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>ПРЕЗИДЕНТ АССОЦИАЦИИ</li>
          <li onClick={() => scrollToSection(section12Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>СПЕЦИАЛИЗИРОВАННЫЕ ОРГАНЫ АССОЦИАЦИИ</li>
          <li onClick={() => scrollToSection(section13Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>КОНТРОЛЬ ЗА ДЕЯТЕЛЬНОСТЬЮ АССОЦИАЦИИ</li>
          <li onClick={() => scrollToSection(section14Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>ЗАИНТЕРЕСОВАННЫЕ ЛИЦА АССОЦИАЦИИ. КОНФЛИКТ ИНТЕРЕСОВ</li>
          <li onClick={() => scrollToSection(section15Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>ПОРЯДОК РЕОРГАНИЗАЦИИ И ЛИКВИДАЦИИ</li>
          <li onClick={() => scrollToSection(section16Ref)}><span class="statute-icon-wrapper">
          <img src={ArrowImg} className="my-icon-style" alt="ArrowImg" />
            </span>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</li>
        </ul>
        <div  id="1" ref={section1Ref} className="sub-title">1. ОБЩИЕ ПОЛОЖЕНИЯ</div>
        <p className="page-text">1.1. <b>МЕЖДУНАРОДНАЯ АССОЦИАЦИЯ КРАСОТЫ И УХОДА "IABC"</b> (далее - "Ассоциация") является объединением юридических лиц и граждан, основанным на обязательном членстве и созданным для представления и защиты общих, в том числе профессиональных, интересов, для достижения общественно полезных целей, а также иных, не противоречащих закону и имеющих некоммерческий характер целей.</p>
        <p className="page-text">1.2. Являясь саморегулируемой организацией, Ассоциация объединяет субъектов предпринимательской деятельности исходя из единства отрасли производства товаров, работ, услуг; единства отрасли произведенных товаров, работ, услуг, а также, объединяет субъектов профессиональной деятельности определенного вида.</p>
        <p className="page-text">1.3. Ассоциация осуществляет свою деятельность в соответствии с Конституцией Российской Федерации, Гражданским кодексом Российской Федерации, Федеральным законом Российской Федерации "О некоммерческих организациях", Федеральным законом "О саморегулируемых организациях", настоящим Уставом и иным действующим законодательством РФ.</p>
        <p className="page-text">1.4. Полное наименование Ассоциации на русском языке: <b>МЕЖДУНАРОДНАЯ АССОЦИАЦИЯ КРАСОТЫ И УХОДА "IABC"</b>. Сокращенное наименование Ассоциации на русском языке: <b>СРО (саморегулируемая организация) "IABC"</b>. Полное наименование Ассоциации на английском языке: <b>INTERNATIONAL ASSOCIATION OF BEAUTY AND CARE "IABC"</b>. Сокращенное название Ассоциации на английском языке: <b>"IABC"</b>.</p>
        <p className="page-text">1.5. Место нахождения Ассоциации: Российская Федерация, город Москва.</p>
        <p className="page-text">1.6. Ассоциация создана без ограничения срока деятельности.</p>
        <div  id="2" ref={section2Ref} className="sub-title pt-30">2. ПРАВОВОЕ ПОЛОЖЕНИЕ АССОЦИАЦИИ</div>
        <p className="page-text">2.1. Ассоциация приобретает права юридического лица с момента его государственной регистрации.</p>
        <p className="page-text">2.2. Ассоциация имеет на праве собственности обособленное имущество, учитываемое на его самостоятельном балансе, отвечает по своим обязательствам этим имуществом, может от своего имени приобретать и осуществлять - имущественные и неимущественные права, нести обязанности, быть истцом и ответчиком в суде, для достижения своих целей имеет право заключать договоры.</p>
        <p className="page-text">2.3. Ассоциация имеет самостоятельный баланс; вправе в установленном порядке открывать счета, в том числе валютные, в банках и иных кредитных организациях на территории Российской Федерации и за ее пределами.</p>
        <p className="page-text">2.4. Ассоциация имеет круглую печать, содержащую его полное наименование на русском языке.</p>
        <p className="page-text">2.5. Для достижения целей, предусмотренных настоящим Уставом, Ассоциация может создавать другие некоммерческие организации и вступать в ассоциации и союзы.</p>
        <p className="page-text">2.6. Имущество, переданное Ассоциации его членами, является собственностью Ассоциации. Члены Ассоциации не отвечают по его обязательствам, а Ассоциация не отвечает по обязательствам своих членов.</p>
        <p className="page-text">2.7. Ассоциация приобретает статус саморегулируемой организации с момента внесения сведений об Ассоциации в государственный реестр саморегулируемых организаций.</p>
        <p className="page-text">2.8. Ассоциация утрачивает статус саморегулируемой организации с даты исключения сведений об Ассоциации из государственного реестра саморегулируемых организаций.</p>
        <div id="3" ref={section3Ref} className="sub-title pt-30">3. ЦЕЛИ И ПРЕДМЕТ ДЕЯТЕЛЬНОСТИ АССОЦИАЦИИ</div>
        <p className="page-text">3.1. Основной целью деятельности Международной Федерации Красоты и Ухода "IABC" является объединение субъектов предпринимательской деятельности в сфере индустрии красоты; представление и защита общих, в том числе профессиональных интересов членов Ассоциации в развитии индустрии красоты. Содействие творческой и деловой активности парикмахеров, косметологов, мастеров по наращиванию ресниц, ногтевого сервиса; эстетического педикюра, подологии, аппаратного и эстетического макияжа, объединение их усилий, направленных на расширение сотрудничества в области оказания салонных услуг; эффективного использования современных технологий в повседневной деятельности.</p>
        <p className="page-text">3.2. Предметом деятельности Ассоциации является:</p>
        <p className="page-text">3.2.1. Разработка и установление условий своего членства.</p>
        <p className="page-text">3.2.2. Установление и применение мер дисциплинарного воздействия в отношении своих членов.</p>
        <p className="page-text">3.2.3. Ведение реестра своих членов.</p>
        <p className="page-text">3.2.4. Представление интересов своих членов в их отношениях с федеральными органами государственной власти, органами государственной власти субъектов Российской Федерации, органами местного самоуправления, а также с международными профессиональными организациями в сфере индустрии красоты, профессионального образования и сферы услуг.</p>
        <p className="page-text">3.2.5. Разработка и утверждение технических регламентов, стандартов и правил профессиональной деятельности в сфере индустрии красоты.</p>
        <p className="page-text">3.2.6. Разработка и утверждение правил деловой и профессиональной этики в сфере индустрии красоты.</p>
        <p className="page-text">3.2.7. Разработка правил контроля за соблюдением членами Ассоциации требований к выдаче свидетельств о допуске, требований стандартов саморегулируемых организаций и правил саморегулирования.</p>
        <p className="page-text">3.2.8. Контроль за профессиональной деятельностью своих членов в части соблюдения ими требований федерального законодательства, стандартов и правил Ассоциации, условий членства.</p>
        <p className="page-text">3.2.9. Организация информационного и методического обеспечения своих членов в сфере индустрии красоты.</p>
        <p className="page-text">3.2.10. Образование третейских судов для разрешения споров, возникающих между своими членами.</p>
        <p className="page-text">3.2.11. Анализ деятельности своих членов на основании информации, представляемой ими в саморегулируемую организацию в форме отчетов в порядке, установленном Уставом или иным документом, утвержденными решением общего собрания членов Ассоциации.</p>
        <p className="page-text">3.2.12. Оспаривание от имени Международной Ассоциации Красоты и Ухода (IABC) в установленном законодательством Российской Федерации порядке любых актов, решений и (или) действий (бездействия) органов государственной власти Российской Федерации, органов государственной власти субъектов Российской Федерации и органов местного самоуправления, нарушающих права и законные интересы Ассоциации, ее члена или членов, либо создающих угрозу такого нарушения.</p>
        <p className="page-text">3.2.13. Организация и проведение выставок, конгрессов, форумов, чемпионатов, фестивалей в сфере индустрии красоты как на территории Российской Федерации, так и за рубежом.</p>
        <p className="page-text">3.2.14. Создание и развитие новых коллективных форм информационного, правового и рекламного обслуживания.</p>
        <p className="page-text">3.2.15. Использование возможности фонда IABC для оказания помощи в приобретении профессиональных навыков малоимущим семьям.</p>
        <p className="page-text">3.2.16. Содействие обеспечению доступности образования для талантливой молодежи.</p>
        <p className="page-text">3.2.17. Содействие развитию эффективных форм организации профессионального учебного процесса.</p>
        <p className="page-text">3.2.18. Содействие продвижению новых технологий в индустрии красоты.</p>
        <p className="page-text">3.2.19. Усовершенствование конкурсного движения.</p>
        <p className="page-text">3.2.20. Организация профессионального обучения и повышения квалификации мастеров в России и за рубежом.</p>
        <p className="page-text">3.2.21. Поиск и продвижение талантов.</p>
        <p className="page-text">3.2.22. Разработка и реализация программ развития и совершенствования сферы услуг.</p>
        <p className="page-text">3.2.23. Участие в общественной деятельности, направленной на популяризацию сферы услуг.</p>
        <p className="page-text">3.2.24. Содействие в разработке и продвижении уникальных программ и технологий.</p>
        <p className="page-text">3.2.25. Создание сети региональных отделений и координация их деятельности.</p>
        <p className="page-text">3.2.26. Создание и проведение профессиональных форумов и конгрессов.</p>
        <p className="page-text">3.2.27. Подготовка высококвалифицированных судей, обучение практике современного судейства.</p>
        <p className="page-text">3.2.28. Подготовка высококвалифицированных инструкторов.</p>
        <p className="page-text">3.2.29. Подготовка высококвалифицированных администраторов для салонов красоты.</p>
        <p className="page-text">3.2.30. Оказание консультативной и иной помощи членам IABC.</p>
        <p className="page-text">3.2.31. Обмен опытом и мнениями среди членов IABC.</p>
        <p className="page-text">3.2.32. Создание команды сборной России для участия в российских и международных конкурсах.</p>
        <p className="page-text">3.2.33. Разработка и утверждение документов IABC, предусмотренных законодательством Российской Федерации для саморегулируемых организаций в области индустрии красоты, а также контроль за соблюдением членами IABC требований этих документов.</p>
        <p className="page-text">3.2.34. Ведение иных реестров Ассоциации, предусмотренных внутренними документами Ассоциации или предусмотренные законодательством Российской Федерации.</p>
        <p className="page-text">3.2.35. Осуществление контроля за предпринимательской деятельностью своих членов в части соблюдения ими требований стандартов и правил, условий членства в Ассоциации.</p>
        <p className="page-text">3.2.36. Обеспечение имущественной ответственности членов Ассоциации перед потребителями произведенных ими товаров (работ, услуг) и иными лицами.</p>
        <p className="page-text">3.2.37. Обеспечение доступа к информации об Ассоциации посредством создания и ведения официального сайта Ассоциации в информационно-телекоммуникационной сети «Интернет», обеспечение информационной открытости деятельности членов Ассоциации путём опубликования информации о деятельности Ассоциации на официальном сайте Ассоциации.</p>
        <p className="page-text">3.2.38. Рассмотрение жалоб и других обращений на действия членов Ассоциации в соответствии с требованиями законодательства Российской Федерации и внутренних документов Ассоциации.</p>
        <p className="page-text">3.2.39. Анализ деятельности членов Ассоциации на основании информации, предоставляемой ими в Ассоциацию в порядке, установленном действующим законодательством и внутренними документами Ассоциации.</p>
        <p className="page-text">3.2.40. Осуществление общественного контроля в порядке, установленном законодательством Российской Федерации, в сфере закупок.</p>
        <p className="page-text">3.2.41. Содействие в представительстве и защите прав и законных интересов своих членов, проведение конференций, совещаний, круглых столов, выставок фестивалей красоты, премий, профессиональных международных и Российских чемпионатов сфере индустрии красоты и вопросам саморегулирования деятельности в индустрии красоты.</p>
        <p className="page-text">3.2.42. Подготовка и выпуск информационных материалов по вопросам индустрии красоты.</p>
        <p className="page-text">3.2.43. Осуществление функций саморегулируемой организации в сфере индустрии красоты, в соответствии с действующим законодательством Российской Федерации.</p>
        <p className="page-text">3.3. Ассоциация вправе осуществлять предпринимательскую деятельность, не запрещенную законом и соответствующую целям, для достижения которых она создана.</p>
        <p className="page-text">3.4. Отдельными видами деятельности, перечень которых определяется специальными федеральными законами, Ассоциация может заниматься только при получении специального разрешения (лицензии).</p>
        <div id="4" ref={section4Ref} className="sub-title pt-30">4. ПРИНЦИПЫ ДЕЯТЕЛЬНОСТИ АССОЦИАЦИИ</div>
        <p className="page-text">4.1. При осуществлении своей деятельности, Ассоциация действует на основе следующих принципов:</p>
        <p className="page-text">4.1.1. Добровольное вступление юридического лица и индивидуального предпринимателя в члены Ассоциации и выход из нее в любое время по усмотрению члена Ассоциации.</p>
        <p className="page-text">4.1.2. Равенство прав и обязанностей всех членов Ассоциации.</p>
        <p className="page-text">4.1.3. Принятие решений органами управления Ассоциации с учетом интересов большинства членов Ассоциации.</p>
        <p className="page-text">4.1.4. Саморегулирование, информационная открытость.</p>
        <p className="page-text">4.1.5. Осуществление деятельности членами Ассоциации в соответствии с требованиями, установленными законодательством Российской Федерации, настоящим Уставом, иными внутренними документами Ассоциации.</p>
        <p className="page-text">4.1.6. Контроль со стороны Ассоциации за деятельностью своих членов, являющейся предметом саморегулирования.</p>
        <p className="page-text">4.2. Ассоциация не вправе учреждать хозяйственные товарищества и общества, осуществляющие предпринимательскую деятельность, являющуюся предметом саморегулирования для Ассоциации, и становиться участником таких хозяйственных товариществ и обществ.</p>
        <p className="page-text">4.3. Ассоциация не вправе, если иное не предусмотрено законодательством Российской Федерации, осуществлять следующие действия и совершать следующие сделки:</p>
        <p className="page-text">4.3.1. Предоставлять принадлежащее ей имущество в залог в обеспечение исполнения обязательств иных лиц.</p>
        <p className="page-text">4.3.2. Выдавать поручительства за иных лиц, за исключением своих работников.</p>
        <p className="page-text">4.3.3. Приобретать акции, облигации и иные ценные бумаги, выпущенные членами Ассоциации, за исключением случаев, если такие ценные бумаги обращаются на организованных торгах.</p>
        <p className="page-text">4.3.4. Обеспечивать исполнение своих обязательств залогом имущества членов Ассоциации, выданными ими гарантиями и поручительствами.</p>
        <p className="page-text">4.3.5. Выступать посредником (комиссионером, агентом) по реализации произведенных членами Ассоциации товаров (работ, услуг).</p>
        <p className="page-text">4.3.6. Совершать иные сделки в случаях, предусмотренных законодательством Российской Федерации.</p>
        <p className="page-text">4.3.7. При формировании компенсационного фонда принимать решение о ликвидации компенсационного фонда.</p>
        <div id="5" ref={section5Ref} className="sub-title pt-30">5. ФУНКЦИИ АССОЦИАЦИИ</div>
        <p className="page-text">Для достижения своих целей Ассоциация осуществляет следующие основные функции:</p>
        <p className="page-text">5.1. Разработка и утверждение документов:</p>
        <p className="page-text">5.1.1. С момента вступления в силу соответствующих изменений в федеральное законодательство, Ассоциация разрабатывает и утверждает следующие документы:</p>
        <p className="page-text">5.1.1.1. Стандарты и правила предпринимательской деятельности (далее - стандарты и правила саморегулируемой организации), устанавливающие требования к осуществлению предпринимательской деятельности, обязательные для выполнения всеми членами саморегулируемой организации.</p>
        <p className="page-text">5.1.1.2. Устанавливающие порядок осуществления контроля за соблюдением членами саморегулируемой организации требований стандартов и правил саморегулируемой организации, условий членства в саморегулируемой организации и порядок применения мер дисциплинарного воздействия в отношении членов саморегулируемой организации.</p>
        <p className="page-text">5.1.1.3. О раскрытии информации, устанавливающее порядок обеспечения информационной открытости деятельности саморегулируемой организации и деятельности ее членов.</p>
        <p className="page-text">5.1.1.4. О компенсационном фонде возмещения вреда.</p>
        <p className="page-text">5.1.1.5. О компенсационном фонде обеспечения договорных обязательств.</p>
        <p className="page-text">5.1.1.6. О реестре членов Ассоциации.</p>
        <p className="page-text">5.1.1.7. О процедуре рассмотрения жалоб на действия (бездействие) членов саморегулируемой организации и иных обращений, поступивших в саморегулируемую организацию.</p>
        <p className="page-text">5.1.1.8. О проведении саморегулируемой организацией анализа деятельности своих членов на основании информации, представляемой ими в форме документальных отчетов.</p>
        <p className="page-text">5.1.1.9. О членстве в саморегулируемой организации, в том числе о требованиях к членам саморегулируемой организации, о размере, порядке расчета и уплаты вступительного взноса, членских взносов.</p>
        <p className="page-text">5.1.1.10. Квалификационные стандарты саморегулируемой организации в соответствующей сфере деятельности.</p>
        <p className="page-text">5.1.2. Дополнительные документы:</p>
        <p className="page-text">5.1.2.1. Ассоциация вправе разработать и утвердить документы о страховании членами саморегулируемой организации риска гражданской ответственности и риска ответственности за нарушение условий договора на оказание услуг, а также иные внутренние документы.</p>
        <p className="page-text">5.1.3. Применение мер дисциплинарного воздействия:</p>
        <p className="page-text">Ассоциация осуществляет применение мер дисциплинарного воздействия в отношении члена Ассоциации, допустившего нарушение требований к выдаче свидетельств о допуске к работам, стандартов и правил саморегулирования.</p>
        <p className="page-text">5.1.4. Контроль и обеспечение информационной открытости:</p>
        <p className="page-text">Ассоциация осуществляет контроль за соблюдением членами Ассоциации требований к выдаче свидетельства о допуске к работам, обеспечивает информационную открытость своей деятельности и деятельности своих членов в порядке, установленном законодательством Российской Федерации.</p>
        <p className="page-text">5.1.5. Ведение реестра и предоставление информации:</p>
        <p className="page-text">Ассоциация осуществляет ведение реестра членов Ассоциации в соответствии с требованиями законодательства, предоставляет по запросу заинтересованного лица выписку из реестра членов Ассоциации.</p>
        <p className="page-text">5.1.6. Хранение документации:</p>
        <p className="page-text">Ассоциация осуществляет ведение и хранение дел членов Ассоциации.</p>
        <p className="page-text">5.1.7. Представление интересов:</p>
        <p className="page-text">С момента вступления в силу соответствующих изменений в федеральное законодательство, Ассоциация представляет интересы членов Ассоциации в их отношениях с органами государственной власти Российской Федерации, органами государственной власти субъектов Российской Федерации, органами местного самоуправления.</p>
        <p className="page-text">5.1.8. Организация профессионального обучения и сертификация:</p>
        <p className="page-text">Ассоциация организует профессиональное обучение, аттестацию и проводит независимую оценку квалификации членов Ассоциации или сертификацию произведенных ими товаров, работ, услуг, если иное не установлено федеральными законами.</p>
        <p className="page-text">5.1.9. Оспаривание актов и решений:</p>
        <p className="page-text">Ассоциация от своего имени оспаривает в установленном законодательством порядке любые акты, решения и (или) действия.</p>
        <p className="page-text">5.1.10. оказывать консультационную поддержку своим членам по вопросам, касающимся предпринимательской деятельности в сфере индустрии красоты, а также вопросам саморегулирования; </p>
        <p className="page-text">5.1.11. проводить анализ рынка услуг индустрии красоты и разрабатывать рекомендации по его развитию и совершенствованию; </p>
        <p className="page-text">5.1.12. организовывать мероприятия по продвижению и популяризации услуг индустрии красоты, включая выставки, конференции, семинары и другие форматы; </p>
        <p className="page-text">5.1.13. сотрудничать с международными организациями и ассоциациями, направленными на развитие индустрии красоты, с целью обмена опытом и внедрения передовых практик; </p>
        <p className="page-text">5.1.14. поддерживать диалог и взаимодействие с общественностью, включая активное информирование о деятельности Ассоциации и достижениях ее членов; </p>
        <p className="page-text">5.1.15. разрабатывать и реализовывать проекты по социальной поддержке и развитию членов Ассоциации, а также иных социально значимых инициатив; </p>
        <p className="page-text">5.1.16. проводить мониторинг и анализ законодательства, касающегося сферы индустрии красоты, и предпринимать меры по защите интересов членов Ассоциации в процессе законодательного регулирования; </p>
        <p className="page-text">5.1.17. осуществлять иные функции, не противоречащие законодательству Российской Федерации и целям Ассоциации.</p>
        <p className="page-text">5.1.18. участвовать в обсуждении проектов федеральных законов и иных нормативных правовых актов Российской Федерации, законов и иных нормативных правовых актов субъектов Российской Федерации, а также направлять в органы государственной власти Российской Федерации, органы государственной власти субъектов Российской Федерации и органы местного самоуправления заключения о результатах проводимых ее независимых экспертиз проектов нормативных правовых актов; </p>
        <p className="page-text">5.1.19. вносить на рассмотрение органов государственной власти Российской Федерации, органов государственной власти субъектов Российской Федерации и органов местного самоуправления предложения по вопросам формирования и реализации соответственно государственной политики и осуществляемой органами местного самоуправления политики в отношении предмета саморегулирования; </p>
        <p className="page-text">5.1.20. запрашивать в органах государственной власти Российской Федерации, органах государственной власти субъектов Российской Федерации и органах местного самоуправления информацию и получать от этих органов информацию, необходимую для выполнения Ассоциацией возложенных на нее функций в установленном законодательством Российской Федерации порядке; </p>
        <p className="page-text">5.1.21. разрабатывать или участвовать в разработке проектов федеральных законов и иных нормативных правовых актов Российской Федерации, законов и иных нормативных правовых актов субъектов Российской Федерации, государственных программ, стратегий, а также разрабатывать методические пособия, рекомендации, регламенты, справочные пособия для нужд членов Ассоциации; </p>
        <p className="page-text">5.1.22. осуществлять общественный контроль в сфере закупок; </p>
        <p className="page-text">5.1.23. осуществлять иные функции, необходимые для достижения целей деятельности Ассоциации и не противоречащие законодательству Российской Федерации.</p>
        <div id="6" ref={section6Ref} className="sub-title pt-30">6. ИСТОЧНИКИ ФОРМИРОВАНИЯ ИМУЩЕСТВА АССОЦИАЦИИ </div>
        <p className="page-text">6.1. Имущество Ассоциации используется для достижения целей, ради которых она создана. Общие принципы формирования и использования имущества Ассоциации определяются Общим собранием членов Ассоциации. </p>
        <p className="page-text">6.2. Ассоциация может иметь в собственности земельные участки, здания, строения, сооружения, жилищный фонд, транспорт, оборудование, инвентарь, имущество культурно-просветительного и оздоровительного назначения, денежные средства, акции, другие ценные бумаги и иное имущество, которое отражается на самостоятельном балансе Ассоциации. </p>
        <p className="page-text">6.3. Ассоциации принадлежит право собственности на денежные средства, имущество и иные объекты собственности, переданные физическими и юридическими лицами в форме взноса, дара, пожертвования или по завещанию. </p>
        <p className="page-text">6.4. Ассоциация вправе привлекать в порядке, установленном законодательством Российской Федерации, дополнительные финансовые, в том числе валютные, ресурсы, пожертвования и целевые взносы юридических и физических лиц, в том числе иностранных.</p>
        <p className="page-text">6.5. Источниками формирования имущества Ассоциации в денежной и иных формах являются: </p>
        <p className="page-text">6.5.1. вступительные, ежегодные и целевые взносы членов Ассоциации (далее "членские взносы"); </p>
        <p className="page-text">6.5.2. добровольные имущественные взносы и пожертвования; </p>
        <p className="page-text">6.5.3. доходы, получаемые от собственности Ассоциации;</p>
        <p className="page-text">6.5.4. выручка от реализации товаров, работ, услуг; </p>
        <p className="page-text">6.5.5. дивиденды (доходы, проценты), получаемые по акциям, облигациям, другим ценным бумагам и вкладам; </p>
        <p className="page-text">6.5.6. средства, полученные от оказания услуг по предоставлению информации, раскрытие которой может осуществляться на платной основе; </p>
        <p className="page-text">6.5.7. средства, полученные от оказания образовательных услуг, связанных с предпринимательской деятельностью, коммерческими или профессиональными интересами членов саморегулируемой организации;</p>
        <p className="page-text">6.5.8. средства, полученные от продажи информационных материалов, связанных с предпринимательской деятельностью, коммерческими или профессиональными интересами членов саморегулируемой организации; </p>
        <p className="page-text">6.5.9. доходы, полученные от размещения денежных средств на банковских депозитах; </p>
        <p className="page-text">6.5.10. другие источники, не противоречащие действующему законодательству.</p>
        <p className="page-text">6.6. Вступительные взносы: размер вступительного взноса для членов Ассоциации определяется Положением о членских взносах и может быть в дальнейшем изменен решением Общего собрания членов Ассоциации. Вступительные взносы оплачиваются в течение 30 дней со дня принятия Общим собранием членов Ассоциации решения о приеме в члены Ассоциации.</p>
        <p className="page-text">6.7. Ежегодные членские взносы: размер, порядок и сроки внесения ежегодных членских взносов определяются в Положении о членских взносах Ассоциации и могут быть в последующем изменены решением Общего собрания членов Ассоциации.</p>
        <p className="page-text">6.8. Целевые взносы: целевые взносы предназначены для финансирования конкретных мероприятий и программ. Срок, размер и форма их внесения устанавливаются Общим собранием членов Ассоциации.</p>
        <p className="page-text">6.9. Члены Ассоциации обязаны своевременно уплачивать членские и иные взносы в порядке, размере и сроках, определяемых в Положении о членских взносах.</p>
        <p className="page-text">6.10. Вступительные и ежегодные членские взносы используются на нужды и содержание Ассоциации и обеспечение деятельности, предусмотренной настоящим Уставом.</p>
        <p className="page-text">6.11. Прибыль, полученная Ассоциацией, не может перераспределяться между членами Ассоциации и используется только для выполнения уставных целей.</p>
        <p className="page-text">6.12. Прибыль, которая может возникнуть в результате осуществления Ассоциацией уставной деятельности, не распределяется между Учредителями и членами Ассоциации, а направляется в фонд целевого финансирования, а также, в случае необходимости, в другие фонды.</p>
        <div id="7" ref={section7Ref} className="sub-title pt-30">7. ПОРЯДОК ПРИЕМА В ЧЛЕНЫ АССОЦИАЦИИ</div>
        <p className="page-text">Членами Международной Ассоциации Профессионалов Индустрии Красоты и Ухода могут стать только лица, которые продемонстрировали выдающиеся достижения в своей профессиональной деятельности. Ассоциация стремится объединить самых исключительных специалистов, чьи достижения подтверждают их значимый вклад в развитие индустрии красоты и ухода.</p>
        <p className="page-text"><b>7.1 Критерии приема в Ассоциацию:</b></p>
        <p className="page-text">Для того чтобы стать членом Ассоциации, кандидаты должны соответствовать следующим строгим критериям:</p>
        <p className="page-text"><b>7.1.1 Выдающиеся профессиональные достижения:</b></p>
        <p className="page-text">Кандидаты должны доказать наличие выдающихся достижений, признанных в профессиональной среде на международном уровне. Подтверждением могут служить победы в престижных международных конкурсах (как минимум две победы), в которых участвуют профессиональные работники бьюти сферы.</p>
        <p className="page-text"><b>7.1.2 Публичное признание достижений:</b></p>
        <p className="page-text">Кандидаты должны иметь опубликованные статьи, интервью или упоминания о своей профессиональной деятельности в крупных национальных изданиях, либо в профессиональных изданиях, которые специализируются в индустрии красоты и ухода.</p>
        <p className="page-text"><b>7.1.3 Лидерство и инновации:</b></p>
        <p className="page-text">Кандидаты должны продемонстрировать вклад в развитие индустрии через инновационные разработки или лидирующую роль в формировании новых стандартов в области красоты и ухода. Подтверждением может служить наличие патентов, разработанных методик, либо определенных техник проведения процедур, которые активно применяются в бьюти-сфере.</p>
        <p className="page-text"><b>7.1.4 Участие в международных профессиональных мероприятиях:</b></p>
        <p className="page-text">Кандидаты должны регулярно принимать участие в международных конференциях, семинарах, форумах в качестве спикера или выступать в качестве судей на профессиональных конкурсах, тем самым подтверждая свой статус лидера в индустрии.</p>
        <p className="page-text"><b>7.2 Процедура приема в члены Ассоциации:</b></p>
        <p className="page-text">7.2.1. Оценка достижений и принятие окончательного решения о принятии в члены Ассоциации осуществляется на Общем собрании членов Ассоциации.</p>
        <p className="page-text">7.2.2. На Общем собрании выступают эксперты с национальным или международным признанием в области деятельности кандидата, которые проводят всестороннюю оценку выдающихся достижений кандидата.</p>
        <p className="page-text">7.2.3. После обсуждения всех аспектов профессиональной деятельности кандидата, члены Ассоциации, участвующие в собрании, проводят голосование. Окончательное решение о приеме в члены Ассоциации принимается на основании результатов этого голосования. </p>
        <p className="page-text">7.2.4. Результаты голосования и окончательное решение фиксируются в Протоколе Общего собрания, который является официальным подтверждением приёма кандидата в члены Ассоциации.</p>
        <p className="page-text">7.2.5. После принятия решения кандидат вносится в Список членов Ассоциации на официальном сайте Ассоциации (https://iabc-association.com/), а также получает Свидетельство члена Ассоциации.</p>
        <p className="page-text"><b>7.3 Процесс выхода и исключения из Ассоциации:</b></p>
        <p className="page-text">Члены Ассоциации могут быть исключены в случае, если их достижения больше не соответствуют высокому уровню, требуемому для поддержания членства. Исключение может быть инициировано по результатам регулярной оценки достижений и профессионального статуса членов.</p>
        <div id="8" ref={section8Ref} className="sub-title pt-30">8. ПРАВА И ОБЯЗАННОСТИ ЧЛЕНОВ АССОЦИАЦИИ</div>
        <p className="page-text">8.1. Члены Ассоциации имеют равные права независимо от времени вступления в Ассоциацию и срока пребывания в числе ее членов.</p>
        <p className="page-text">8.2. Каждый член Ассоциации вправе участвовать в управлении делами Ассоциации лично либо через своего представителя. Представитель члена Ассоциации действует на основе законных полномочий или надлежащим образом оформленной доверенности.</p>
        <p className="page-text">8.3. Члены Ассоциации имеют право:</p>
        <p className="page-text">8.3.1. Участвовать в управлении делами Ассоциации, в том числе требовать (в соответствии с положениями настоящего Устава) созыва Общих собраний членов Ассоциации, вносить предложения в повестку дня Общих собраний и кандидатуры в список кандидатов для избрания в органы управления Ассоциации, участвовать в Общих собраниях и голосовать по всем вопросам повестки дня Общих собраний членов;</p>
        <p className="page-text">8.3.2. Получать информацию о деятельности Ассоциации в установленном настоящим Уставом порядке;</p>
        <p className="page-text">8.3.3. По своему усмотрению выходить из Ассоциации;</p>
        <p className="page-text">8.3.4. Обращаться в органы управления Ассоциации по любым вопросам, связанным с его деятельностью;</p>
        <p className="page-text">8.3.5. Передавать имущество в собственность Ассоциации;</p>
        <p className="page-text">8.3.6. Получать в случае ликвидации Ассоциации часть его имущества, оставшегося после расчетов с кредиторами, переданного ими в собственность Ассоциации;</p>
        <p className="page-text">8.3.7. Избирать и быть избранными в органы управления Ассоциации;</p>
        <p className="page-text">8.3.8. Вносить предложения по совершенствованию деятельности Ассоциации;</p>
        <p className="page-text">8.3.9. Участвовать в разработке документов Ассоциации;</p>
        <p className="page-text">8.3.10. Участвовать в мероприятиях, проводимых Ассоциацией;</p>
        <p className="page-text">8.3.11. Непосредственно обращаться в Ассоциацию за содействием и помощью в защите своих интересов, связанных с целями и предметом деятельности Ассоциации;</p>
        <p className="page-text">8.3.12. Пользоваться информационной базой Ассоциации, исключающей допуск к служебной, коммерческой и иной закрытой информации о членах Ассоциации;</p>
        <p className="page-text">8.3.13. Вносить предложения в повестку дня Общего собрания членов Ассоциации;</p>
        <p className="page-text">8.3.14. В случаях и в порядке, которые предусмотрены законом и уставом Ассоциации, получать информацию о деятельности Ассоциации и знакомиться с ее бухгалтерской и иной документацией;</p>
        <p className="page-text">8.3.15. Обжаловать решения органов Ассоциации, влекущие гражданско-правовые последствия, в случаях и в порядке, которые предусмотрены законом;</p>
        <p className="page-text">8.3.16. Требовать, действуя от имени Ассоциации, возмещения причиненных Ассоциации убытков;</p>
        <p className="page-text">8.3.17. Оспаривать, действуя от имени Ассоциации, совершенные ею сделки, по основаниям, предусмотренным законодательством Российской Федерации, и требовать применения последствий их недействительности, а также применения последствий недействительности ничтожных сделок Ассоциации;</p>
        <p className="page-text">8.3.18. Вносить в Ассоциацию добровольные взносы и пожертвования;</p>
        <p className="page-text">8.3.19. На равных началах с другими членами Ассоциации безвозмездно, если иное не предусмотрено законом, пользоваться оказываемыми ею услугами.</p>
        <p className="page-text">8.4. Члены Ассоциации могут иметь и другие права, предусмотренные законодательством Российской Федерации для членов саморегулируемых организаций.</p>
        <p className="page-text">8.5. Члены Ассоциации обязаны:</p>
        <p className="page-text">8.5.1. Соблюдать положения настоящего Устава и других внутренних документов Ассоциации.</p>
        <p className="page-text">8.5.2. Выполнять решения органов управления Ассоциации, принятые в соответствии с их компетенцией.</p>
        <p className="page-text">8.5.3. Своевременно вносить ежегодные членские взносы, размер которых определяется Общим собранием членов Ассоциации.</p>
        <p className="page-text">8.5.4. Оказывать содействие Ассоциации в достижении ее целей.</p>
        <p className="page-text">8.5.5. Строго соблюдать положения настоящего Устава и локальных актов Ассоциации (в том числе стандартов и правил).</p>
        <p className="page-text">8.5.6. Выполнять в полном объеме принятые на себя обязанности по отношению к Ассоциации.</p>
        <p className="page-text">8.5.7. Своевременно и в полном объеме вносить членские и иные взносы Ассоциации.</p>
        <p className="page-text">8.5.8. Участвовать в образовании имущества Ассоциации в необходимом размере в порядке, способом и в сроки, которые предусмотрены действующим законодательством и настоящим Уставом.</p>
        <p className="page-text">8.5.9. Не разглашать конфиденциальную информацию о деятельности Ассоциации.</p>
        <p className="page-text">8.5.10. Участвовать в принятии корпоративных решений, без которых Ассоциация не может продолжать свою деятельность в соответствии с законом, если его участие необходимо для принятия таких решений.</p>
        <p className="page-text">8.5.11. Не совершать действия, заведомо направленные на причинение вреда Ассоциации.</p>
        <p className="page-text">8.5.12. Не совершать действия (бездействие), которые существенно затрудняют или делают невозможным достижение целей, ради которых создана Ассоциация.</p>
        <p className="page-text">8.5.13. Выполнять решения органов управления Ассоциации, принятые в рамках их компетенции.</p>
        <p className="page-text">8.5.14. Уважать интересы других членов Ассоциации, избегать действий, способных нанести ущерб другим членам или самой Ассоциации, строго соблюдать условия договоров, контрактов и соглашений.</p>
        <p className="page-text">8.5.15. Уведомлять Ассоциацию в письменной форме или путем направления электронного документа о наступлении любых событий, влекущих за собой изменение информации, содержащейся в реестре членов Ассоциации, в течение трех рабочих дней со дня, следующего за днем наступления таких событий.</p>
        <p className="page-text">8.5.16. Раскрывать информацию о своей деятельности, подлежащую раскрытию в соответствии с законодательством Российской Федерации и установленными Ассоциацией требованиями.</p>
        <p className="page-text">8.5.17. Предоставлять Ассоциации информацию, необходимую ей для решения вопросов, связанных с деятельностью Ассоциации или ее члена, в том числе, для контроля за деятельностью членов Ассоциации.</p>
        <p className="page-text">8.5.18. Обеспечивать гражданскую ответственность, в том числе дополнительную, перед потребителями и иными лицами, обеспечивать имущественную ответственность иных лиц перед членами Ассоциации, а также контроль за соблюдением указанных требований.</p>
        <p className="page-text">8.5.19. Применять все разумные меры для предупреждения причинения вреда вследствие недостатков работ, которые оказывают влияние на безопасность объектов капитального строительства.</p>
        <p className="page-text">8.5.20. Добросовестно пользоваться правами члена Ассоциации.</p>
        <p className="page-text">8.6. Члены Ассоциации могут нести и другие обязанности, предусмотренные законодательством Российской Федерации для членов саморегулируемых организаций, основанных на членстве лиц, осуществляющих строительство, реконструкцию, капитальный ремонт объектов капитального строительства.</p>
        <div id="9" ref={section9Ref} className="sub-title pt-30">9. ОРГАНЫ УПРАВЛЕНИЯ АССОЦИАЦИИ</div>
        <p className="page-text">9.1. Органами управления Ассоциации являются:</p>
        <p className="page-text">9.1.1. Общее собрание членов Ассоциации.</p>
        <p className="page-text">9.1.2. Президент Ассоциации.</p>
        <p className="page-text">9.1.3. Наблюдательный совет.</p>
        <p className="page-text">9.2. Члены органов управления Ассоциации, при осуществлении своих прав и исполнении своих обязанностей, должны действовать в интересах Ассоциации, осуществлять свои права и исполнять обязанности в отношении Ассоциации добросовестно и разумно.</p>
        <p className="page-text">9.3. Функции постоянно действующего коллегиального органа управления осуществляются Общим собранием членов Ассоциации.</p>
        <div id="10" ref={section10Ref} className="sub-title pt-30">10. ОБЩЕЕ СОБРАНИЕ ЧЛЕНОВ АССОЦИАЦИИ</div>
        <p className="page-text">10.1. Высшим органом управления Ассоциации является Общее собрание членов Ассоциации.</p>
        <p className="page-text">10.2. К компетенции Общего собрания членов Ассоциации относится:</p>
        <p className="page-text">10.2.1. Определение приоритетных направлений деятельности Ассоциации, принципов образования и использования ее имущества.</p>
        <p className="page-text">10.2.2. Утверждение и изменение Устава Ассоциации.</p>
        <p className="page-text">10.2.3. Определение порядка приема в состав членов Ассоциации и исключение членов Ассоциации из Ассоциации.</p>
        <p className="page-text">10.2.4. Утверждение годовых отчетов и бухгалтерской (финансовой) отчетности Ассоциации.</p>
        <p className="page-text">10.2.5. Участие Ассоциации в других юридических лицах, а также принятие решений о создании филиалов и открытии представительств Ассоциации.</p>
        <p className="page-text">10.2.6. Реорганизация и ликвидация Ассоциации; назначение ликвидатора Ассоциации; утверждение ликвидационного баланса.</p>
        <p className="page-text">10.2.7. Избрание ревизора; назначение индивидуального аудитора Ассоциации.</p>
        <p className="page-text">10.2.8. Избрание Президента Ассоциации и досрочное прекращение его полномочий.</p>
        <p className="page-text">10.2.9. Принятие решений о порядке определения размера и способа уплаты членских взносов, о дополнительных имущественных взносах членов Ассоциации в ее имущество и о размере их субсидиарной ответственности по обязательствам Ассоциации.</p>
        <p className="page-text">10.2.10. Утверждение Положения об Общем собрании членов Ассоциации, Положения о членстве, Положения о членских взносах и иных положений.</p>
        <p className="page-text">10.2.11. Избрание Председательствующего на Общем собрании членов Ассоциации.</p>
        <p className="page-text">10.2.12. Утверждение повестки дня Общего собрания членов.</p>
        <p className="page-text">10.3. Вопросы, относящиеся к компетенции Общего собрания членов Ассоциации, не могут быть переданы на решение Президенту Ассоциации.</p>
        <p className="page-text">10.4. Вопросы Общего собрания членов Ассоциации, перечисленные в подпунктах 10.2.1-10.2.10 пункта 10.2. настоящего Устава относятся к исключительной компетенции Общего собрания членов Ассоциации. Вопросы, относящиеся к исключительной компетенции Общего собрания членов Ассоциации, принимается квалифицированным большинством голосов (более 2/3 от общего числа присутствующих на собрании).</p>
        <p className="page-text">10.5. Общее собрание членов Ассоциации правомочно, если на указанном собрании присутствует более половины членов Ассоциации. При отсутствии кворума для проведения Общего собрания членов Ассоциации объявляется дата проведения повторного Общего собрания членов Ассоциации не позднее чем через 20 (Двадцать) дней с той же повесткой дня.</p>
        <p className="page-text">10.6. На Общем собрании членов Ассоциации каждый член Ассоциации обладает одним голосом. Подсчет и обработку бюллетеней осуществляет Секретарь Общего собрания. Решение Общего собрания принимается большинством голосов членов, присутствующих на собрании.</p>
        <p className="page-text">10.7. Общее Собрание членов Ассоциации может быть очередным или внеочередным. Очередное Общее Собрание членов Ассоциации проводится не реже одного раза в год, не позднее шести месяцев с начала соответствующего финансового года, в котором оно проводится. Очередное Общее Собрание членов Ассоциации созывается Президентом Ассоциации.</p>
        <p className="page-text">10.8. Общее Собрание членов Ассоциации, организуемое раньше срока, указанного в п. 10.5. настоящего Устава, является внеочередным. Внеочередное Общее Собрание членов Ассоциации может быть созвано по инициативе Президента Ассоциации, Ревизора Ассоциации или не менее 1/3 членов Ассоциации, определяемой на дату созыва внеочередного Общего Собрания.</p>
        <p className="page-text">10.9. Общее Собрание членов Ассоциации созывается путем оповещения всех членов не позднее, чем за тридцать дней до даты проведения Общего Собрания.</p>
        <p className="page-text">10.10. Извещение членов Ассоциации о дате, месте проведения и повестке дня Общего Собрания осуществляется путем уведомления почтовой.</p>
        <p className="page-text">10.11. Повестка дня Общего Собрания членов Ассоциации утверждается Общим собранием членов Ассоциации. Члены Ассоциации могут вносить вопросы в повестку дня Общего Собрания путем направления соответствующего заявления Председательствующему на Общем собрании не позднее, чем за пятнадцать дней до даты проведения Общего Собрания.</p>
        <p className="page-text">10.12. Иные вопросы, связанные с созывом, подготовкой и проведением Общего собрания членов Ассоциации, регулируются внутренним документом, утвержденным Общим собранием членов Ассоциации.</p>
        <div id="11" ref={section11Ref} className="sub-title pt-30">11. ПРЕЗИДЕНТ АССОЦИАЦИИ</div>
        <p className="page-text">11.1. В Ассоциации избирается единоличный исполнительный орган – Президент.</p>
        <p className="page-text">11.2. Президент руководит текущей деятельностью Ассоциации, организует выполнение решений Общего собрания членов Ассоциации, а также решает все вопросы организации работы Ассоциации, которые не составляют компетенцию Общего собрания членов Ассоциации, определенную настоящим Уставом.</p>
        <p className="page-text">11.3. Президент избирается Общим собранием членов Ассоциации на срок 3 (три) года.</p>
        <p className="page-text">11.4. Президент Ассоциации при осуществлении своих прав и исполнении обязанностей должен действовать в интересах Ассоциации, осуществлять свои права и исполнять обязанности в отношении Ассоциации добросовестно и разумно. Президент Ассоциации несет ответственность перед Ассоциацией за убытки, причиненные Ассоциации своими виновными действиями (бездействием) в случаях, предусмотренных законодательством Российской Федерации и трудовым договором, заключаемым с Ассоциацией.</p>
        <p className="page-text">11.5. Президент Ассоциации без доверенности действует от имени Ассоциации, в том числе, с учетом ограничений, предусмотренных действующим законодательством и настоящим Уставом: </p>
        <p className="page-text">11.5.1. обеспечивает выполнение планов деятельности Ассоциации, необходимых для решения его задач; </p>
        <p className="page-text">11.5.2. организует ведение бухгалтерского учета и отчетности в Ассоциации;</p>
        <p className="page-text">11.5.3. распоряжается имуществом Ассоциации, совершает сделки от имени Ассоциации в пределах полномочий, предоставленных ему настоящим Уставом, выдает доверенности, открывает в банках, иных кредитных организациях расчетные и иные счета Ассоциации; </p>
        <p className="page-text">11.5.4. издает приказы, утверждает (принимает) инструкции, локальные нормативные акты и иные внутренние документы Ассоциации по вопросам его компетенции, дает указания, обязательные для исполнения всеми работниками Ассоциации; </p>
        <p className="page-text">11.5.5. утверждает Положения о филиалах и представительствах Ассоциации; </p>
        <p className="page-text">11.5.6. утверждает организационную структуру Ассоциации; </p>
        <p className="page-text">11.5.7. в соответствии с организационной структурой Ассоциации утверждает штатное расписание и должностные оклады работников Ассоциации; </p>
        <p className="page-text">11.5.8. осуществляет в отношении работников Ассоциации права и обязанности работодателя, предусмотренные трудовым законодательством; </p>
        <p className="page-text">11.5.9. решает иные вопросы текущей деятельности Ассоциации, за исключением вопросов, отнесенных к компетенции Общего собрания членов Ассоциации. </p>
        <p className="page-text">11.5.10. разрабатывает и утверждает перспективные планы по реализации основных направлений деятельности Ассоциации; </p>
        <p className="page-text">11.5.11. создает постоянно действующие или временные комитеты, комиссии, советы Ассоциации по проблемам различных сфер деятельности Ассоциации и утверждает положения о них, за исключением случаев, отнесенных настоящим Уставом к компетенции Общего собрания членов Ассоциации;</p>
        <p className="page-text">11.5.12. подготавливает отчеты о финансово-хозяйственной деятельности Ассоциации, о выполнении решений Общего собрания членов; </p>
        <p className="page-text">11.5.13. принимает решения по вопросам, отнесенным к компетенции высших органов управления хозяйственных обществ, 100 (сто) процентов уставного капитала которых принадлежит Ассоциации; </p>
        <p className="page-text">11.5.14. принимает решения о выдвижении Ассоциациям кандидатур для избрания на должность единоличного исполнительного органа в иные органы управления, органы контроля, а также кандидатуры аудитора организаций любых организационно-правовых форм, в которых участвует Ассоциация;</p>
        <p className="page-text">11.5.15. утверждает планы и мероприятия по обучению и повышению квалификации работников Ассоциации; </p>
        <p className="page-text">11.5.16. устанавливает социальные льготы и гарантии работникам Ассоциации; </p>
        <p className="page-text">11.5.17. решает иные вопросы руководства текущей деятельностью Ассоциации в соответствии с решениями Общего собрания членов и иных внутренних документов Ассоциации, а также вопросов, представленных на рассмотрение Президентом Ассоциации.</p>
        <p className="page-text">11.6. Полномочия Президента Ассоциации и порядок его деятельности определяются настоящим Уставом и трудовым договором.</p>
        <div id="12" ref={section12Ref} className="sub-title pt-30">12. СПЕЦИАЛИЗИРОВАННЫЕ ОРГАНЫ АССОЦИАЦИИ</div>
        <p className="page-text">12.1. Контрольный комитет Ассоциации - специализированный орган Ассоциации, задачей которого является контроль за соблюдением ее членами требований к выдаче свидетельств о допуске, требований стандартов и правил саморегулирования Ассоциации.</p>
        <p className="page-text">12.2. Порядок работы Контрольного комитета Ассоциации, формы проведения контроля, а также иные вопросы, связанные с его деятельностью, регулируются Положением о Контрольном комитете Ассоциации, являющимся внутренним документом Ассоциации, утвержденным Общим собранием членов Ассоциации.</p>
        <p className="page-text">12.3. Дисциплинарный комитет Ассоциации - специализированный орган Ассоциации, задачей которого является рассмотрение дел о применении в отношении членов Ассоциации мер дисциплинарного воздействия.</p>
        <p className="page-text">12.4. Порядок работы Дисциплинарного комитета Ассоциации и все вопросы, связанные с его деятельностью, регулируются Положением о Дисциплинарном комитете Ассоциации, являющимся внутренним документом Ассоциации, утвержденным Общим собранием членов Ассоциации.</p>
        <p className="page-text">12.5. Помимо указанных специализированных органов Ассоциации, решениями Ассоциации может быть предусмотрено создание на временной или постоянной основе иных специализированных органов.</p>
        <div id="13" ref={section13Ref} className="sub-title pt-30">13. КОНТРОЛЬ ЗА ДЕЯТЕЛЬНОСТЬЮ АССОЦИАЦИИ</div>
        <p className="page-text">13.1. Ассоциация ведет бухгалтерский учет и статистическую отчетность в порядке, установленном законодательством Российской Федерации.</p>
        <p className="page-text">13.2. Ассоциация предоставляет информацию о своей деятельности органам государственной статистики и налоговым органам, учредителям и иным лицам в соответствии с законодательством Российской Федерации и настоящим Уставом.</p>
        <p className="page-text">13.3. Размеры и структура доходов Ассоциации, а также сведения о размерах и составе имущества Ассоциации, его расходов, численности и составе работников, об оплате их труда, об использовании безвозмездного труда граждан в деятельности Ассоциации не могут быть предметом коммерческой тайны.</p>
        <div id="14" ref={section14Ref} className="sub-title pt-30">14. ЗАИНТЕРЕСОВАННЫЕ ЛИЦА АССОЦИАЦИИ. КОНФЛИКТ ИНТЕРЕСОВ</div>
        <p className="page-text">14.1. Под заинтересованными лицами понимаются лица, входящие в состав органов управления Ассоциации, ее работники, действующие на основании трудового договора или гражданско-правового договора.</p>
        <p className="page-text">14.2. Под личной заинтересованностью указанных заинтересованных лиц понимается материальная или иная заинтересованность, которая влияет или может повлиять на обеспечение прав и законных интересов Ассоциации и (или) ее членов.</p>
        <p className="page-text">14.3. Под конфликтом интересов понимается ситуация, при которой личная заинтересованность указанных заинтересованных лиц влияет или может повлиять на исполнение ими своих профессиональных обязанностей и (или) влечет за собой возникновение противоречия между такой личной заинтересованностью и законными интересами Ассоциации или угрозу возникновения противоречия, которое способно привести к причинению вреда законным интересам Ассоциации.</p>
        <p className="page-text">14.4. Заинтересованные лица должны соблюдать интересы Ассоциации, прежде всего в отношении целей ее деятельности, и не должны использовать возможности, связанные с осуществлением ими своих профессиональных обязанностей, или допускать использование таких возможностей в целях, противоречащих целям, указанным в настоящем уставе Ассоциации.</p>
        <p className="page-text">14.5. В случае, если заинтересованное лицо имеет заинтересованность в сделке, стороной которой является или намеревается быть Ассоциация, а также в случае иного противоречия интересов указанного лица и Ассоциации в отношении существующей или предполагаемой сделки:</p>
        <p className="page-text">14.5.1. оно обязано сообщить о своей заинтересованности Общему собранию членов Ассоциации до момента принятия решения о заключении сделки;</p>
        <p className="page-text">14.5.2. сделка должна быть одобрена Общим собранием членов Ассоциации.</p>
        <p className="page-text">14.6. Сделка, в совершении которой имеется заинтересованность и которая совершена с нарушением конфликта интересов Ассоциации, может быть признана судом недействительной.</p>
        <div id="15" ref={section15Ref} className="sub-title pt-30">15. ПОРЯДОК РЕОРГАНИЗАЦИИ И ЛИКВИДАЦИИ</div>
        <p className="page-text">15.1. Реорганизация Ассоциации осуществляется в порядке, предусмотренном действующим законодательством Российской Федерации. Реорганизация может быть осуществлена в форме слияния, присоединения, разделения, выделения и преобразования.</p>
        <p className="page-text">15.2. Ассоциация по решению Общего собрания членов может быть преобразована в общественную организацию, автономную некоммерческую организацию или фонд.</p>
        <p className="page-text">15.3. Ликвидация Ассоциации производится по решению Общего собрания членов Ассоциации или суда.</p>
        <p className="page-text">15.4. Общее собрание членов Ассоциации назначает ликвидационную комиссию и устанавливает порядок и сроки ликвидации Ассоциации.</p>
        <p className="page-text">15.5. С момента назначения ликвидационной комиссии к ней переходят полномочия по управлению делами Ассоциации.</p>
        <p className="page-text">15.6. Ликвидационная комиссия помещает в органах печати публикацию о ликвидации Ассоциации, порядке и сроке заявления требований его кредиторами.</p>
        <p className="page-text">15.7. По окончании срока для предъявления требований кредиторами ликвидационная комиссия составляет промежуточный ликвидационный баланс. Ликвидационный промежуточный баланс утверждается Общим собранием членов Ассоциации.</p>
        <p className="page-text">15.8. После завершения расчетов с кредиторами ликвидационная комиссия составляет ликвидационный баланс, который утверждается Общим собранием членов Ассоциации или органом, принявшим решение о ликвидации.</p>
        <p className="page-text">15.9. Оставшееся после удовлетворения требований кредиторов имущество либо его стоимость подлежит распределению между членами Ассоциации в пределах размера их имущественных взносов. Оставшееся после удовлетворения требований кредиторов имущество распределяется на цели, предусмотренные уставом и (или) на благотворительные цели.</p>
        <p className="page-text">15.10. Остальная часть имущества, стоимость которого превышает размер имущественных взносов членов Ассоциации, направляется на цели, в интересах которых Ассоциация была создана, и (или) на благотворительные цели.</p>
        <p className="page-text">15.11. Ликвидация Ассоциации считается завершенной, а Ассоциация - прекратившей существование, с момента внесения об этом записи в единый государственный реестр юридических лиц.</p>
        <p className="page-text">15.12. При реорганизации деятельности Ассоциации все документы (управленческие, финансово-хозяйственные, по личному составу и др.) передаются в соответствии с установленными правилами ее правопреемнику.</p>
        <p className="page-text">15.13. При отсутствии правопреемника документы постоянного хранения, имеющие научно-историческое значение, передаются на государственное хранение в архивы ("Мосгорархив"), документы по личному составу (приказы, личные дела, карточки учета, лицевые счета и т.п.) передаются на хранение в архив административного округа, на территории которого находится Ассоциация. Передача и упорядочение документов осуществляются силами и за счет средств Ассоциации в соответствии с требованиями архивных органов.</p>
        <div id="16" ref={section16Ref} className="sub-title pt-30">16. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</div>
        <p className="page-text">16.1. Изменения и дополнения в Устав утверждаются на Общем собрании членов Ассоциации.</p>
        <p className="page-text">16.2. Решение Общего собрания о внесении изменений и дополнений в Устав принимается квалифицированным большинством голосов (более 2/3 от общего числа присутствующих на собрании).</p>
        <p className="page-text">16.3. Изменения и дополнения в Устав подлежат государственной регистрации и вступают в силу с момента регистрации.</p>


    </div>
    </div>
  );
}

export default Statute;
