const members = [
  {
    name: "Виктория Бондаренко",
    country: "Украина",
    achievements: "Профессиональный художник, топ мастер-дизайнер ногтевых технологий"
  },
  {
    name: "Диана Громак",
    country: "Россия",
    achievements: "Преподаватель, мастер маникюра"
  },
  {
    name: "Ирина Кравчук",
    country: "Россия",
    achievements: "Известная визажистка, владелица школы макияжа"
  },
  {
    name: "Вера Мирошниченко",
    country: "Россия",
    achievements: "Ведущий преподаватель и автор множества программ обучения"
  },
  {
    name: "Дмитрий Стрельников",
    country: "Россия",
    achievements: "Эксперт в области парикмахерского искусства, основатель салонов красоты"
  },
  {
    name: "Екатерина Павлова",
    country: "Украина",
    achievements: "Известный мастер маникюра и педикюра, победитель международных конкурсов"
  },
  {
    name: "Лейла Саидова",
    country: "Азербайджан",
    achievements: "Косметолог, основатель сети салонов красоты"
  },
  {
    name: "Армине Аветисян",
    country: "Армения",
    achievements: "Владелица успешного центра эстетической медицины"
  },
  {
    name: "Наталья Гордеева",
    country: "Беларусь",
    achievements: "Визажистка, известная своими уникальными стилями макияжа для фотосессий и показов моды"
  },
  {
    name: "Анастасия Беляева",
    country: "Россия",
    achievements: "Основательница косметического бренда"
  },
  {
    name: "Айгуль Исмагилова",
    country: "Казахстан",
    achievements: "Эксперт по перманентному макияжу, основательница академии красоты"
  },
  {
    name: "Алена Толоконникова",
    country: "Киргизия",
    achievements: "Мастер по наращиванию ресниц и бровей, популяризатор техник объемного макияжа"
  },
  {
    name: "Светлана Георгиева",
    country: "Молдова",
    achievements: "Известный косметолог, владелица салона красоты и SPA-центра"
  },
  {
    name: "Анастасия Беляева",
    country: "Россия",
    achievements: "Основательница косметического бренда"
  },
  {
    name: "Муабир Шодмонов",
    country: "Таджикистан",
    achievements: "Специалист по мужским стрижкам и уходу за волосами, основатель студии мужской красоты"
  },
  {
    name: "Гулбахор Халилова",
    country: "Туркменистан",
    achievements: "Эксперт по уходу за кожей, владелица косметического бутика"
  },
  {
    name: "Гульнара Исаева",
    country: "Узбекистан",
    achievements: "Успешный предприниматель в области красоты, владелица сети салонов красоты и фитнес-центров"
  },
  {
    name: "Дарья Жукова",
    country: "Россия",
    achievements: "Известный стилист, работающий с знаменитостями"
  },
  {
    name: "Наталья Петрова",
    country: "Украина",
    achievements: "Эксперт по уходу за кожей, основательница бьюти-блога"
  },
  {
    name: "Эльмира Алиева",
    country: "Азербайджан",
    achievements: "Владелица салона красоты, специализирующегося на свадебных образах"
  },
  {
    name: "Анна Симоненко",
    country: "Беларусь",
    achievements: "Мастер перманентного макияжа, обладательница международных наград"
  },
  {
    name: "Дарья Миронова",
    country: "Казахстан",
    achievements: "Визажистка, работающая в театре и кино"
  },
  {
    name: "Элеонора Сергеева",
    country: "Киргизия",
    achievements: "Специалист по оформлению бровей, владелица студии микроблейдинга"
  },
  {
    name: "Инга Аванесян",
    country: "Украина",
    achievements: "Мастер ногтевого дизайна, обладательница международных наград"
  },
  {
    name: "Вера Иванова",
    country: "Молдова",
    achievements: "Мастер ногтевого сервиса, проводящая мастер-классы по дизайну ногтей"
  },
  {
    name: "Гулнара Аманова",
    country: "Таджикистан",
    achievements: "Визажистка, обучающая визажистов в своей школе"
  },
  {
    name: "Джамиля Карапетян",
    country: "Туркменистан",
    achievements: "Косметолог, специализирующаяся на лазерной эпиляции"
  },
  {
    name: "Шахло Хакимова",
    country: "Узбекистан",
    achievements: "Специалист по уходу за волосами, основательница сети салонов красоты"
  },
  {
    name: "Лейла Исмаилова",
    country: "Азербайджан",
    achievements: "Владелица бренда органической косметики"
  },
  {
    name: "Анжелика Попова",
    country: "Казахстан",
    achievements: "Эксперт по массажу лица и тела, проводящая курсы обучения массажу"
  },
  {
    name: "София Абрамова",
    country: "Россия",
    achievements: "Известная косметолог, автор книг о уходе за кожей"
  },
  {
    name: "Ольга Иванова",
    country: "Украина",
    achievements: "Стилист-имиджмейкер, проводит тренинги по подбору стиля"
  },
  {
    name: "Дарья Махиня",
    country: "Украина",
    achievements: "Выдающийся мастер перманентного макияжа, обладательница международных наград"
  },
  {
    name: "Алина Петрова",
    country: "Беларусь",
    achievements: "Мастер по наращиванию ресниц, создательница собственной линии материалов для наращивания"
  },
  {
    name: "Мария Лебедева",
    country: "Киргизия",
    achievements: "Профессиональный массажист, специализирующийся на лечебном массаже"
  },
  {
    name: "Лейла Гаджиева",
    country: "Казахстан",
    achievements: 'Профессиональный визажист, автор инновационной методики "KazBeauty Glow"'
  },
  {
    name: "Людмила Кравченко",
    country: "Узбекистан",
    achievements: "Косметолог, основательница линии косметических средств"
  },
  {
    name: "Евгения Сидорова",
    country: "Россия",
    achievements: "Владелица сети салонов красоты, предлагающих широкий спектр услуг"
  },
  {
    name: "Айгуль Смагулова",
    country: "Казахстан",
    achievements: "Визажистка-перформер, работающая на телевидении и в рекламе"
  },
  {
    name: "Лейла Абдуллаева",
    country: "Таджикистан",
    achievements: "Мастер маникюра и педикюра, специализирующаяся на дизайне ногтей"
  },
  {
    name: "Марина Гусева",
    country: "Украина",
    achievements: "Косметолог, работающая с использованием инновационных методов ухода за кожей"
  },
  {
    name: "Анна Ковалева",
    country: "Россия",
    achievements: "Известный художник-макияжист, работающий в модной индустрии"
  },
  {
    name: "Виктория Михайлова",
    country: "Беларусь",
    achievements: "Эксперт по уходу за волосами, основательница школы парикмахеров"
  },
  {
    name: "Алина Исаева",
    country: "Киргизия",
    achievements: "Мастер по наращиванию ресниц, проводящая мастер-классы по объемному наращиванию"
  },
  {
    name: "Светлана Александрова",
    country: "Молдова",
    achievements: "Косметолог, специализирующаяся на антивозрастных процедурах"
  },
  {
    name: "Дарина Соколова",
    country: "Туркменистан",
    achievements: "Парикмахер-стилист, владелица студии эксклюзивных причесок"
  },
  {
    name: "Елена Попова",
    country: "Узбекистан",
    achievements: "Мастер по перманентному макияжу, создательница собственного метода нанесения"
  },
  // Новые участники
  {
    name: "Артем Литвинов",
    country: "Молдова",
    achievements: "Парикмахер-стилист, специалист по окрашиванию"
  },
  {
    name: "Лилия Витальева",
    country: "Киргизия",
    achievements: "Массажист, специалист по рефлексотерапии"
  },
  {
    name: "Римма Захарова",
    country: "Молдова",
    achievements: "Косметолог, специалист по глубокому очищению кожи"
  },
  {
    name: "Елена Рогова",
    country: "Россия",
    achievements: "Косметолог, специалист по мезотерапии"
  },
  {
    name: "Максим Карпов",
    country: "Россия",
    achievements: "Визажист, мастер макияжа для фотосессий"
  },
  {
    name: "Жанна Иванова",
    country: "Россия",
    achievements: "Специалист по моделированию бровей"
  },
  {
    name: "Екатерина Ковалёва",
    country: "Украина",
    achievements: "Эксперт по шугарингу и восковой эпиляции"
  },
  {
    name: "Алексей Жуков",
    country: "Беларусь",
    achievements: "Мастер перманентного макияжа"
  },
  {
    name: "Галина Антонова",
    country: "Украина",
    achievements: "Мастер маникюра и педикюра"
  },
  {
    name: "Марина Богданова",
    country: "Казахстан",
    achievements: "Косметолог, специалист по безинъекционной мезотерапии"
  },
  {
    name: "Николай Власов",
    country: "Россия",
    achievements: "Специалист по инъекционной косметологии"
  },
  {
    name: "Юлия Никифорова",
    country: "Беларусь",
    achievements: "Парикмахер-стилист, специалист по коротким стрижкам"
  },
  {
    name: "Ирина Степанова",
    country: "Россия",
    achievements: "Эксперт по свадебным укладкам"
  },
  {
    name: "Инна Левина",
    country: "Россия",
    achievements: "Эксперт по аппаратной косметологии"
  },
  {
    name: "Дмитрий Лебедев",
    country: "Киргизия",
    achievements: "Косметолог, специалист по контурной пластике"
  },
  {
    name: "Елена Громова",
    country: "Украина",
    achievements: "Парикмахер, мастер стрижек и окрашивания"
  },
  {
    name: "Алина Романова",
    country: "Россия",
    achievements: "Визажист, мастер повседневного и вечернего макияжа"
  },
  {
    name: "Арсений Фролов",
    country: "Украина",
    achievements: "Барбер, специалист по мужским стрижкам"
  },
  {
    name: "Лидия Алимова",
    country: "Беларусь",
    achievements: "Специалист по перманентному макияжу"
  },
  {
    name: "Зоя Каримова",
    country: "Узбекистан",
    achievements: "Косметолог, специалист по пилингу"
  },
  {
    name: "Андрей Громов",
    country: "Казахстан",
    achievements: "Парикмахер-стилист, эксперт по стрижкам"
  },
  {
    name: "Олеся Королёва",
    country: "Украина",
    achievements: "Специалист по антицеллюлитным программам"
  },
  {
    name: "Светлана Новикова",
    country: "Россия",
    achievements: "Мастер маникюра и педикюра"
  },
  {
    name: "Карина Волкова",
    country: "Молдова",
    achievements: "Косметолог, эксперт по уходу за зрелой кожей"
  },
  {
    name: "Андрей Ковалев",
    country: "Россия",
    achievements: "Визажист, специалист по мужскому гриму"
  },
  {
    name: "Анастасия Базарова",
    country: "Молдова",
    achievements: "Парикмахер-стилист, специалист по колористике"
  },
  {
    name: "Анна Иванова",
    country: "Россия",
    achievements: "Парикмахер-стилист, специалист по свадебным укладкам"
  },
  {
    name: "Инна Савченко",
    country: "Молдова",
    achievements: "Стилист, мастер вечерних причесок"
  },
  {
    name: "Игорь Серов",
    country: "Молдова",
    achievements: "Мастер по стрижкам и укладкам"
  },
  {
    name: "Антонина Соколова",
    country: "Россия",
    achievements: "Специалист по уходу за волосами, эксперт по окрашиванию"
  },
  {
    name: "Наталья Вовк",
    country: "Украина",
    achievements: "Косметолог, эксперт по инъекционным методикам"
  },
  {
    name: "Светлана Глебова",
    country: "Украина",
    achievements: "Эксперт по контурной пластике и омоложению"
  },
  {
    name: "Роман Васильев",
    country: "Казахстан",
    achievements: "Специалист по моделированию бровей и ресниц"
  },
  {
    name: "Екатерина Орлова",
    country: "Беларусь",
    achievements: "Специалист по перманентному макияжу"
  },
  {
    name: "Екатерина Борисова",
    country: "Украина",
    achievements: "Косметолог, специалист по аппаратным методикам"
  },
  {
    name: "Юлия Константинова",
    country: "Беларусь",
    achievements: "Парикмахер, специалист по окрашиванию волос"
  },
  {
    name: "Вера Лапшина",
    country: "Молдова",
    achievements: "Специалист по уходу за кожей и инъекционным процедурам"
  },
  {
    name: "Вадим Романов",
    country: "Украина",
    achievements: "Специалист по наращиванию ресниц"
  },
  {
    name: "Оксана Романова",
    country: "Киргизия",
    achievements: "Визажист, мастер свадебного макияжа"
  },
  {
    name: "Вера Рябова",
    country: "Казахстан",
    achievements: "Косметолог, эксперт в антивозрастных процедурах"
  },
  {
    name: "Анна Морозова",
    country: "Украина",
    achievements: "Специалист по биоревитализации и мезотерапии"
  },
  {
    name: "Петр Соколов",
    country: "Россия",
    achievements: "Визажист, мастер креативного макияжа"
  },
  {
    name: "Вера Соколова",
    country: "Беларусь",
    achievements: "Визажист, мастер свадебного макияжа"
  },
  {
    name: "Екатерина Сергеева",
    country: "Беларусь",
    achievements: "Специалист по наращиванию ресниц и ламинированию"
  },
  {
    name: "Оксана Давыдова",
    country: "Казахстан",
    achievements: "Визажист, мастер макияжа для невест"
  },
  {
    name: "Тимур Алиев",
    country: "Узбекистан",
    achievements: "Специалист по массажу, мастер восточных техник"
  },
  {
    name: "Светлана Громова",
    country: "Беларусь",
    achievements: "Парикмахер, эксперт по укладкам"
  },
  {
    name: "Галина Глебова",
    country: "Россия",
    achievements: "Мастер маникюра, специалист по дизайну ногтей"
  },
  {
    name: "Валентин Тарасов",
    country: "Казахстан",
    achievements: "Парикмахер-стилист, мастер окрашивания"
  },
  {
    name: "Наталья Сидорова",
    country: "Беларусь",
    achievements: "Мастер маникюра и педикюра"
  },
  {
    name: "Алёна Ивашкина",
    country: "Казахстан",
    achievements: "Специалист по аппаратному уходу за кожей"
  },
  {
    name: "Дмитрий Головин",
    country: "Россия",
    achievements: "Мастер маникюра, специалист по дизайну ногтей"
  },
  {
    name: "Елена Жукова",
    country: "Россия",
    achievements: "Визажист, мастер арт-макияжа"
  },
  {
    name: "Марина Тарасова",
    country: "Молдова",
    achievements: "Эксперт по шугарингу и депиляции"
  },
  {
    name: "Полина Сидорова",
    country: "Беларусь",
    achievements: "Мастер маникюра, специалист по арт-дизайну ногтей"
  },
  {
    name: "Алина Ткаченко",
    country: "Узбекистан",
    achievements: "Косметолог, специалист по уходу за проблемной кожей"
  },
  {
    name: "Илья Захаров",
    country: "Россия",
    achievements: "Барбер, мастер бритья и укладки бороды"
  },
  {
    name: "Жанна Петрова",
    country: "Беларусь",
    achievements: "Мастер перманентного макияжа и микроблейдинга"
  },
  {
    name: "Мария Лазарева",
    country: "Киргизия",
    achievements: "Специалист по эстетической косметологии"
  },
  {
    name: "Диана Шарапова",
    country: "Киргизия",
    achievements: "Массажист, специализируется на лимфодренажных процедурах"
  },
  {
    name: "Михаил Гребенщиков",
    country: "Россия",
    achievements: "Барбер, мастер классических мужских стрижек"
  },
  {
    name: "Лилия Котова",
    country: "Казахстан",
    achievements: "Мастер шугаринга и восковой эпиляции"
  },
  {
    name: "Антон Лазарев",
    country: "Беларусь",
    achievements: "Косметолог, специалист по инъекционным методам"
  },
  {
    name: "Надежда Ефимова",
    country: "Киргизия",
    achievements: "Специалист по восточным техникам массажа"
  },
  {
    name: "Александр Павлов",
    country: "Беларусь",
    achievements: "Визажист, специалист по свадебному макияжу"
  },
  {
    name: "Татьяна Игнатова",
    country: "Украина",
    achievements: "Специалист по инъекционным методикам, косметолог"
  },
  {
    name: "Лейсан Мусина",
    country: "Казахстан",
    achievements: "Парикмахер, мастер по окрашиванию волос"
  },
  {
    name: "Марина Громова",
    country: "Россия",
    achievements: "Визажист, специалист по макияжу для фотосессий"
  },
  {
    name: "Зинаида Романова",
    country: "Киргизия",
    achievements: "Специалист по глубокому расслабляющему массажу"
  },
  {
    name: "Артемий Шилов",
    country: "Киргизия",
    achievements: "Парикмахер, специалист по укладкам"
  },
  {
    name: "Алла Туманова",
    country: "Украина",
    achievements: "Эксперт по перманентному макияжу"
  },
  {
    name: "Денис Лобанов",
    country: "Узбекистан",
    achievements: "Визажист, специалист по сценическому макияжу"
  },
  {
    name: "Аида Ташкенова",
    country: "Узбекистан",
    achievements: "Косметолог, специалист по аппаратным процедурам"
  },
  {
    name: "Евгений Морозов",
    country: "Россия",
    achievements: "Косметолог, эксперт по аппаратной косметологии"
  },
  {
    name: "Олег Козлов",
    country: "Украина",
    achievements: "Визажист, мастер вечернего макияжа"
  },
  {
    name: "Сергей Матвеев",
    country: "Украина",
    achievements: "Парикмахер-стилист, мастер по стрижкам"
  },
  {
    name: "Татьяна Мещерякова",
    country: "Казахстан",
    achievements: "Визажист, мастер fashion-макияжа"
  },
  {
    name: "Кристина Васильева",
    country: "Узбекистан",
    achievements: "Визажист, специалист по вечернему макияжу"
  },
  {
    name: "Маргарита Кузьмина",
    country: "Россия",
    achievements: "Эксперт по косметическим процедурам и уходу за кожей"
  },
  {
    name: "Наталья Овчинникова",
    country: "Молдова",
    achievements: "Косметолог, специалист по антивозрастным процедурам"
  },
  {
    name: "Олег Котов",
    country: "Беларусь",
    achievements: "Специалист по депиляции и шугарингу"
  },
  {
    name: "Валерия Нестерова",
    country: "Украина",
    achievements: "Визажист, мастер вечернего макияжа"
  }
];

export default members;
